import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/EN/translation.json";
import translationES from "./locales/ES/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n

  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: sessionStorage.getItem("language") || navigator.language,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
